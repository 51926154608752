import { useContext, useState, useRef, useEffect } from 'react';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { MutationHookOptions, MutationTuple } from '../types/types';
import { MutationData } from '../data';
import { ApolloCache, DefaultContext, OperationVariables } from '../../core';
import { getApolloContext } from '../context';

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<tdata, TVariables="">,
  options?: MutationHookOptions<tdata, TVariables,="" TContext="">
): MutationTuple<tdata, TVariables,="" TContext,="" TCache=""> {
  const context = useContext(getApolloContext());
  const [result, setResult] = useState({ called: false, loading: false });
  const updatedOptions = options ? { ...options, mutation } : { mutation };

  const mutationDataRef = useRef<mutationdata<tdata, TVariables,="" TContext="">>();
  function getMutationDataRef() {
    if (!mutationDataRef.current) {
      mutationDataRef.current = new MutationData<tdata, TVariables,="" TContext="">({
        options: updatedOptions,
        context,
        result,
        setResult
      });
    }
    return mutationDataRef.current;
  }

  const mutationData = getMutationDataRef();
  mutationData.setOptions(updatedOptions);
  mutationData.context = context;

  useEffect(() => mutationData.afterExecute());

  return mutationData.execute(result);
}
</tdata,></mutationdata<tdata,></tdata,></tdata,></tdata,></any></any>