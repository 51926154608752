import type { Client, ClientOptions } from '@sentry/types';
import { consoleSandbox, logger } from '@sentry/utils';

import { DEBUG_BUILD } from './debug-build';
import { getCurrentHub } from './hub';

/** A class object that can instantiate Client objects. */
export type ClientClass<f extends="" Client,="" O="" ClientOptions=""> = new (options: O) => F;

/**
 * Internal function to create a new SDK client instance. The client is
 * installed and then bound to the current scope.
 *
 * @param clientClass The client class to instantiate.
 * @param options Options to pass to the client.
 */
export function initAndBind<f extends="" Client,="" O="" ClientOptions="">(
  clientClass: ClientClass<f, O="">,
  options: O,
): void {
  if (options.debug === true) {
    if (DEBUG_BUILD) {
      logger.enable();
    } else {
      // use `console.warn` rather than `logger.warn` since by non-debug bundles have all `logger.x` statements stripped
      consoleSandbox(() => {
        // eslint-disable-next-line no-console
        console.warn('[Sentry] Cannot initialize SDK with `debug` option using a non-debug bundle.');
      });
    }
  }
  const hub = getCurrentHub();
  const scope = hub.getScope();
  scope.update(options.initialScope);

  const client = new clientClass(options);
  hub.bindClient(client);
}
</f,></f></f>