import React, { ReactNode } from 'react';

import {
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormControlProps,
	FormGroup,
	FormGroupProps,
	FormHelperTextProps,
	FormLabel,
	FormLabelProps,
	Switch as MuiSwitch,
	SwitchProps as MuiSwitchProps,
} from '@material-ui/core';

import { ErrorMessage, ShowErrorFunc, showErrorOnChange, useFieldForErrors } from './Util';
import { Field, FieldProps } from 'react-final-form';

export interface SwitchData {
	label: ReactNode;
	value: unknown;
	disabled?: boolean;
}

export interface SwitchesProps extends Partial<omit<muiswitchprops, 'onChange'="">> {
	name: string;
	data: SwitchData | SwitchData[];
	label?: ReactNode;
	required?: boolean;
	helperText?: string;
	fieldProps?: Partial<fieldprops<any, any="">>;
	formControlProps?: Partial<formcontrolprops>;
	formGroupProps?: Partial<formgroupprops>;
	formLabelProps?: Partial<formlabelprops>;
	formControlLabelProps?: Partial<formcontrollabelprops>;
	formHelperTextProps?: Partial<formhelpertextprops>;
	showError?: ShowErrorFunc;
}

export function Switches(props: SwitchesProps) {
	const {
		name,
		data,
		label,
		required,
		helperText,
		fieldProps,
		formControlProps,
		formGroupProps,
		formLabelProps,
		formControlLabelProps,
		formHelperTextProps,
		showError = showErrorOnChange,
		...restSwitches
	} = props;

	const itemsData = Array.isArray(data) ? data : [data];
	const single = Array.isArray(data) ? false : true;
	const field = useFieldForErrors(name);
	const isError = showError(field);

	return (
		<formcontrol required="{required}" error="{isError}" {...formControlProps}="">
			{label ? <formlabel {...formLabelProps}="">{label}</formlabel> : <></>}
			<formgroup {...formGroupProps}="">
				{itemsData.map((item: SwitchData, idx: number) => (
					<formcontrollabel key="{idx}" name="{name}" label="{item.label}" value="{single" ?="" undefined="" :="" item.value}="" disabled="{item.disabled}" control="{" <Field="" type="checkbox" render="{({" input:="" {="" name,="" value,="" onChange,="" checked,="" ...restInput="" }="" })=""> (
									<muiswitch name="{name}" value="{value}" onChange="{onChange}" checked="{checked}" disabled="{item.disabled}" required="{required}" inputProps="{{" required,="" ...restInput="" }}="" {...restSwitches}=""></muiswitch>
								)}
								{...fieldProps}
							/>
						}
						{...formControlLabelProps}
					/>
				))}
			</formcontrollabel></formgroup>
			<errormessage showError="{isError}" meta="{field.meta}" formHelperTextProps="{formHelperTextProps}" helperText="{helperText}"></errormessage>
		</formcontrol>
	);
}
</formhelpertextprops></formcontrollabelprops></formlabelprops></formgroupprops></formcontrolprops></fieldprops<any,></omit<muiswitchprops,>