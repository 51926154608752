/* tslint:disable */

import zenObservable from 'zen-observable';

namespace Observable {

}

import { ZenObservable } from './types';

export { ZenObservable };

export type Observer<t> = ZenObservable.Observer<t>;
export type Subscriber<t> = ZenObservable.Subscriber<t>;
export type ObservableLike<t> = ZenObservable.ObservableLike<t>;

export const Observable: {
  new <t>(subscriber: Subscriber<t>): Observable<t>;
  from<r>(
    observable: Observable<r> | ZenObservable.ObservableLike<r> | ArrayLike<r>,
  ): Observable<r>;
  of<r>(...args: Array<r>): Observable<r>;
} = <any>zenObservable;

export interface Observable<t> {
  subscribe(
    observerOrNext: ((value: T) => void) | ZenObservable.Observer<t>,
    error?: (error: any) => void,
    complete?: () => void,
  ): ZenObservable.Subscription;

  forEach(fn: (value: T) => void): Promise<void>;

  map<r>(fn: (value: T) => R): Observable<r>;

  filter(fn: (value: T) => boolean): Observable<t>;

  reduce<r =="" T="">(
    fn: (previousValue: R | T, currentValue: T) => R | T,
    initialValue?: R | T,
  ): Observable<r |="" T="">;

  flatMap<r>(fn: (value: T) => ZenObservable.ObservableLike<r>): Observable<r>;

  from<r>(
    observable: Observable<r> | ZenObservable.ObservableLike<r> | ArrayLike<r>,
  ): Observable<r>;
  of<r>(...args: Array<r>): Observable<r>;
}
</r></r></r></r></r></r></r></r></r></r></r></r></r></t></r></r></void></t></t></any></r></r></r></r></r></r></r></r></t></t></t></t></t></t></t></t></t>