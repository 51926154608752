import { invariant } from '../../utilities/globals';

import * as React from 'react';

import { ApolloClient } from '../../core';
import { getApolloContext } from './ApolloContext';

export interface ApolloProviderProps<tcache> {
  client: ApolloClient<tcache>;
  children: React.ReactNode | React.ReactNode[] | null;
}

export const ApolloProvider: React.FC<apolloproviderprops<any>> = ({
  client,
  children
}) => {
  const ApolloContext = getApolloContext();
  return (
    <apollocontext.consumer>
      {(context: any = {}) => {
        if (client && context.client !== client) {
          context = Object.assign({}, context, { client });
        }

        invariant(
          context.client,
          'ApolloProvider was not passed a client instance. Make ' +
            'sure you pass in your client via the "client" prop.'
        );

        return (
          <apollocontext.provider value="{context}">
            {children}
          </apollocontext.provider>
        );
      }}
    </apollocontext.consumer>
  );
};
</apolloproviderprops<any></tcache></tcache>