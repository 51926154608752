import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { QueryHookOptions, QueryResult } from '../types/types';
import { useBaseQuery } from './utils/useBaseQuery';
import { OperationVariables } from '../../core';

export function useQuery<tdata =="" any,="" TVariables="OperationVariables">(
  query: DocumentNode | TypedDocumentNode<tdata, TVariables="">,
  options?: QueryHookOptions<tdata, TVariables="">
) {
  return useBaseQuery<tdata, TVariables="">(query, options, false) as QueryResult<
    TData,
    TVariables
  >;
}
</tdata,></tdata,></tdata,></tdata>