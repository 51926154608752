import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { SnackbarContentProps } from '../index';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            flexGrow: 'initial',
            minWidth: 288,
        },
    }
});

interface Props extends WithStyles<typeof styles="">, SnackbarContentProps { }

const SnackbarContent = forwardRef<htmldivelement, Props="">(({ classes, className, ...props }, ref) => (
    <div ref="{ref}" className="{clsx(classes.root," className)}="" {...props}=""></div>
))

export default withStyles(styles)(SnackbarContent);
</htmldivelement,></typeof>