import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import ToolbarText from '../_shared/ToolbarText';
import PickerToolbar from '../_shared/PickerToolbar';
import ToolbarButton from '../_shared/ToolbarButton';
import DateTimePickerTabs from './DateTimePickerTabs';
import { useUtils } from '../_shared/hooks/useUtils';
import { DateTimePickerView } from './DateTimePicker';
import { ToolbarComponentProps } from '../Picker/Picker';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMeridiemMode } from '../TimePicker/TimePickerToolbar';

export const useStyles = makeStyles(
  _ => ({
    toolbar: {
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: 'space-around',
    },
    separator: {
      margin: '0 4px 0 2px',
      cursor: 'default',
    },
  }),
  { name: 'MuiPickerDTToolbar' }
);

export const DateTimePickerToolbar: React.FC<toolbarcomponentprops> = ({
  date,
  openView,
  setOpenView,
  ampm,
  hideTabs,
  dateRangeIcon,
  timeIcon,
  onChange,
}) => {
  const utils = useUtils();
  const classes = useStyles();
  const showTabs = !hideTabs && typeof window !== 'undefined' && window.innerHeight > 667;
  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(date, ampm, onChange);
  const theme = useTheme();
  const rtl = theme.direction === 'rtl';

  return (
    <>
      <pickertoolbar isLandscape="{false}" className="{classes.toolbar}">
        <grid container="" justify="center" wrap="nowrap">
          <grid item="" container="" xs="{5}" justify="flex-start" direction="column">
            <div>
              <toolbarbutton variant="subtitle1" onClick="{()" ==""> setOpenView('year')}
                selected={openView === 'year'}
                label={utils.getYearText(date)}
              />
            </toolbarbutton></div>
            <div>
              <toolbarbutton variant="h4" onClick="{()" ==""> setOpenView('date')}
                selected={openView === 'date'}
                label={utils.getDateTimePickerHeaderText(date)}
              />
            </toolbarbutton></div>
          </grid>

          <grid item="" container="" xs="{6}" justify="center" alignItems="flex-end" direction="{rtl" ?="" 'row-reverse'="" :="" 'row'}="">
            <toolbarbutton variant="h3" onClick="{()" ==""> setOpenView('hours')}
              selected={openView === 'hours'}
              label={utils.getHourText(date, ampm!)}
            />

            <toolbartext variant="h3" label=":" className="{classes.separator}"></toolbartext>

            <toolbarbutton variant="h3" onClick="{()" ==""> setOpenView('minutes')}
              selected={openView === 'minutes'}
              label={utils.getMinuteText(date)}
            />
          </toolbarbutton></toolbarbutton></grid>

          {ampm && (
            <grid item="" container="" xs="{1}" direction="column" justify="flex-end">
              <toolbarbutton variant="subtitle1" selected="{meridiemMode" =="=" 'am'}="" label="{utils.getMeridiemText('am')}" onClick="{()"> handleMeridiemChange('am')}
              />

              <toolbarbutton variant="subtitle1" selected="{meridiemMode" =="=" 'pm'}="" label="{utils.getMeridiemText('pm')}" onClick="{()"> handleMeridiemChange('pm')}
              />
            </toolbarbutton></toolbarbutton></grid>
          )}
        </grid>
      </pickertoolbar>

      {showTabs && (
        <datetimepickertabs dateRangeIcon="{dateRangeIcon}" timeIcon="{timeIcon}" view="{openView" as="" DateTimePickerView}="" onChange="{setOpenView}"></datetimepickertabs>
      )}
    </>
  );
};
</toolbarcomponentprops>