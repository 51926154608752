'use client';
import React from 'react';
import { BuilderElement } from '@builder.io/sdk';
import { BuilderBlock } from '../components/builder-block.component';

/**
 * Higher order component for passing Builder.io styles and attributes directly
 * to the component child without wrapping
 *
 * Useful when you need styles etc applied directly to the component without a
 * wrapping element (e.g. div), and you are already forwarding all props and attributes
 * (e.g. <yourcomponent {...props}=""></yourcomponent>)
 *
 * 👉 Important: make sure you always add props.className,
 * even if you supply a className too
 *
 * @example
 * ```tsx
 *
 *    const MyButton = props => <button *="" {...props}="" className="{'my-button" '="" +="" (props.className="" ||="" '')}="">
 *      Hello there!
 *    </button>
 *
 *    const ButtonWithBuilderChildren = noWrap(MyButton)
 *
 *    Builder.registerComponent(ButtonWithBuilderChildren, {
 *      name: 'MyButton',
 *    })
 * ```
 */
export const noWrap = <p extends="" object="">(Component: React.ComponentType</p><p>) => {
  const HOC = React.forwardRef((props: React.PropsWithChildren</p><p> & { attributes?: any }, ref) => {
    const finalProps = {
      ...props,
      ...props.attributes,
    };
    // getting type errors due to `@types/react` version mismatches. Can safely ignore.
    // @ts-ignore
    return <component {...finalProps}="" ref="{ref}"></component>;
  });
  (HOC as any).builderOptions = {
    noWrap: true,
  };

  return HOC;
};
</p>