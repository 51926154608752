import React, { ReactNode } from 'react';

import {
	FormControl,
	FormControlProps,
	FormHelperTextProps,
	InputLabel,
	InputLabelProps,
	MenuItem,
	MenuItemProps,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
} from '@material-ui/core';

import { ErrorMessage, ShowErrorFunc, showErrorOnChange, useFieldForErrors } from './Util';
import { Field, FieldProps } from 'react-final-form';

export interface SelectData {
	label: string;
	value: string | number | string[] | undefined;
	disabled?: boolean;
}

export interface SelectProps extends Partial<omit<muiselectprops, 'onChange'="">> {
	name: string;
	label?: ReactNode;
	required?: boolean;
	multiple?: boolean;
	helperText?: string;
	fieldProps?: Partial<fieldprops<any, any="">>;
	formControlProps?: Partial<formcontrolprops>;
	inputLabelProps?: Partial<inputlabelprops>;
	formHelperTextProps?: Partial<formhelpertextprops>;
	showError?: ShowErrorFunc;
	menuItemProps?: Partial<menuitemprops>;
	data?: SelectData[];
	children?: ReactNode;
}

export function Select(props: SelectProps) {
	const {
		name,
		label,
		data,
		children,
		required,
		multiple,
		helperText,
		fieldProps,
		inputLabelProps,
		formControlProps,
		formHelperTextProps,
		menuItemProps,
		showError = showErrorOnChange,
		...restSelectProps
	} = props;

	if (!data && !children) {
		throw new Error('Please specify either children or data as an attribute.');
	}

	const { variant } = restSelectProps;
	const field = useFieldForErrors(name);
	const isError = showError(field);

	return (
		<field name="{name}" render="{({" input:="" {="" name,="" value,="" onChange,="" ...restInput="" }="" })=""> {
				// prevents an error that happens if you don't have initialValues defined in advance
				const finalValue = multiple && !value ? [] : value;
				const labelId = `select-input-${name}`;

				return (
					<formcontrol required="{required}" error="{isError}" fullWidth="{true}" variant="{variant}" {...formControlProps}="">
						{!!label && (
							<inputlabel id="{labelId}" {...inputLabelProps}="">
								{label}
							</inputlabel>
						)}
						<muiselect name="{name}" value="{finalValue}" onChange="{onChange}" multiple="{multiple}" label="{label}" labelId="{labelId}" inputProps="{{" required,="" ...restInput="" }}="" {...restSelectProps}="">
							{data
								? data.map((item) => (
										<menuitem value="{item.value}" key="{item.value}" disabled="{item.disabled}" {...(menuItemProps="" as="" any)}="">
											{item.label}
										</menuitem>
								  ))
								: children}
						</muiselect>
						<errormessage showError="{isError}" meta="{field.meta}" formHelperTextProps="{formHelperTextProps}" helperText="{helperText}"></errormessage>
					</formcontrol>
				);
			}}
			{...fieldProps}
		/>
	);
}
</field></menuitemprops></formhelpertextprops></inputlabelprops></formcontrolprops></fieldprops<any,></omit<muiselectprops,>