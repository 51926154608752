import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { LazyQueryHookOptions, QueryTuple } from '../types/types';
import { useBaseQuery } from './utils/useBaseQuery';
import { OperationVariables } from '../../core';

export function useLazyQuery<tdata =="" any,="" TVariables="OperationVariables">(
  query: DocumentNode | TypedDocumentNode<tdata, TVariables="">,
  options?: LazyQueryHookOptions<tdata, TVariables="">
) {
  return useBaseQuery<tdata, TVariables="">(query, options, true) as QueryTuple<
    TData,
    TVariables
  >;
}
</tdata,></tdata,></tdata,></tdata>