'use client';
import React from 'react';
import { BuilderElement } from '@builder.io/sdk';
import { withBuilder } from '../functions/with-builder';
import { BuilderBlock as BuilderBlockComponent } from '../components/builder-block.component';

export interface FragmentProps {
  builderBlock?: BuilderElement;
}

class FragmentComponent extends React.Component<fragmentprops> {
  render() {
    return (
      this.props.builderBlock &&
      this.props.builderBlock.children &&
      this.props.builderBlock.children.map((block, index) => (
        <builderblockcomponent block="{block}" key="{block.id}" index="{index}"></builderblockcomponent>
      ))
    );
  }
}

export const Fragment = withBuilder(FragmentComponent, {
  name: 'Core:Fragment',
  canHaveChildren: true,
  noWrap: true,
  static: true,
  hideFromInsertMenu: true,
});
</fragmentprops>