'use client';
import React, { PropsWithChildren } from 'react';
import { BuilderElement } from '@builder.io/sdk';
import { BuilderBlock as BuilderBlockComponent } from '../components/builder-block.component';
import { BuilderStoreContext } from '../store/builder-store';
import { withBuilder } from '../functions/with-builder';

interface StateProviderProps {
  builderBlock?: BuilderElement;
  state: any;
  context?: any;
}

class StateProviderComponent extends React.Component<propswithchildren<stateproviderprops>> {
  render() {
    return (
      <builderstorecontext.consumer>
        {state => (
          <builderstorecontext.provider value="{{" ...state,="" state:="" {="" ...state.state,="" ...this.props.state,="" },="" context:="" ...state.context,="" ...this.props.context,="" }}="">
            {this.props.builderBlock &&
              this.props.builderBlock.children &&
              this.props.builderBlock.children.map((block, index) => (
                <builderblockcomponent block="{block}" key="{block.id}" index="{index}" child="{true}"></builderblockcomponent>
              ))}
            {this.props.children}
          </builderstorecontext.provider>
        )}
      </builderstorecontext.consumer>
    );
  }
}

export const StateProvider = withBuilder(StateProviderComponent, {
  name: 'Builder:StateProvider',
  // TODO: default children
  canHaveChildren: true,
  static: true,
  noWrap: true,
  hideFromInsertMenu: true,
});
</propswithchildren<stateproviderprops>